import React from "react";
import Helmet from "react-helmet";
import desk from "../images/home-office.jpeg";
import profile from "../images/profile-pic.jpeg";
import collage from "../images/collage-2.jpg";

const About = () => (
  <div className="container">
    <Helmet title={"Matt Williams | About Me"} />
    <div className="row justify-content-center align-items-center ">
      <div className="col-md-8">
        <h2 className="mb-5">
          <span>About Me</span>
        </h2>

        <img
          alt="profile"
          className="float-right w-30 w-lg-25 m-3 mt-0"
          src={profile}
        />
        <p>
          I'm a software developer that specializes in building custom
          applications using modern web technologies. Over the past four years,
          I've worked with agile teams to develop products and tools for clients
          that range from small startups to multi-national corporations.
        </p>
        <p className="pb-lg-4">
          A true "full-stack" developer, I am highly skilled at both frontend
          and backend development. I'm also familiar with a variety of DevOps
          tools and best practices. I can go from building an interactive UI one
          week to automating the deployment of cloud infrastructure the next.
          While I'm comfortable wearing many hats, I primarily enjoy working on
          the backend and building solutions that scale.
        </p>
        <div className="float-lg-right">
          <h6 className="mt-4">Types of projects I've worked on:</h6>
          <ul>
            <li>S.a.a.S products</li>
            <li>Ecommerce platforms</li>
            <li>Content heavy websites</li>
            <li>Enterprise tools</li>
            <li>iOS applications</li>
          </ul>
        </div>
        <div className="w-100 w-lg-50 pr-3 pb-4 pb-lg-2">
          <img alt="desk" className="w-100" src={desk} />
        </div>
        <p className="py-3 pt-md-4">
          I studied computer science in college; however most of my skills are
          self-taught, and I have been building web sites and apps since my
          early teens. I currently live in Grand Rapids, Michigan with my
          girlfriend Tess and our golden retriever Jimmy. When I'm not in front
          of a computer screen I like to spend my time hiking, mountain biking,
          and watching sports. I'm originally from Chicago and you can still
          find me there at least once a month visiting friends and family.
        </p>
        <img alt="collage" className="w-100 pb-5" src={collage} />
      </div>
    </div>
  </div>
);

export default About;
